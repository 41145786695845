import { Box, Image, Title } from "@mantine/core";

export default function ServiceCard({ index, iconSrc, title, content = [], cardImgSrc, }) {
    return (
        <>
            <Box className="service-card-container">
                <Box className="top-right-border" />
                <Box
                    p={20}
                    className="service-card"
                >
                    <Title size={170} className="card-index" weight={600}>0{index}</Title>
                    <Image src={iconSrc} width={70} />
                    <Title className="card-title" mt={30} weight={500} color="#6accdb">{title}</Title>
                    <ul className="card-content">
                        {
                            content.map((e, i) => <li key={`nav${i}`}>{e}</li>)
                        }
                        {/* <li>Cash Management System</li>
                    <li>Bills Payment Enrollment System</li>
                    <li>Compliance System for AMLC Requirements</li>
                    <li>Customization of Investment Monitoring System, Enterprise & Mobile</li> */}
                    </ul>
                    <Image src={cardImgSrc} className="card-img" width={380} height={280} radius={10} />
                    <Box className="bottom-left-border" />
                </Box>
            </Box>
        </>
    )
}