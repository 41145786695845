import { Box, Center, Title } from "@mantine/core"
import anime from "animejs"
import { useEffect } from "react"

export default function ICash({ entrance, size }) {

    useEffect(() => {
        var heightRatio = size.height / 1602;
        var widthRatio = window.innerWidth / 2880;
        anime({
            targets: "#icash-screens",
            opacity: 1,
            scale: widthRatio,
            duration: 0,
        })
        if (entrance) {
            anime({
                targets: '#icash',
                opacity: 1,
                duration: 1000,
                easing: "easeInOutQuad"
            })
            if (size.width > 480) {

                var idy, i1y, i2y, i3y;
                if (size.height <= 1024 && size.height > 720) {
                    idy = -45 * heightRatio;
                    i1y = -10 * heightRatio;
                    i2y = -250 * heightRatio;
                    i3y = -75 * heightRatio;
                } else if (size.height <= 720 && size.width > 720) {
                    idy = -45 * heightRatio;
                    i1y = 20 * heightRatio;
                    i2y = -180 * heightRatio;
                    i3y = -75 * heightRatio;
                }
                else {
                    idy = -45 * heightRatio;
                    i1y = -35 * heightRatio;
                    i2y = -250 * heightRatio;
                    i3y = -60 * heightRatio;
                }
                anime({
                    targets: '#icash-device',
                    translateX: "-60%",
                    translateY: `${idy}%`,
                    scale: 1,
                    duration: 1000,
                    easing: "easeInOutQuad"
                })
                anime({
                    targets: '#icash-1',
                    translateX: "-30%",
                    translateY: `${i1y}%`,
                    scale: 1,
                    duration: 1000,
                    easing: "easeInOutQuad"
                })
                anime({
                    targets: '#icash-2',
                    translateX: "-30%",
                    translateY: `${i2y}%`,
                    scale: 1,
                    duration: 1000,
                    easing: "easeInOutQuad"
                })
                anime({
                    targets: '#icash-3',
                    translateX: "-250%",
                    translateY: `${i3y}%`,
                    scale: 1,
                    opacity: 1,
                    duration: 1000,
                    easing: "easeInOutQuad"
                })
            } else {
                anime({
                    targets: '#icash-1',
                    translateX: "-50%",
                    translateY: "-45%",
                    scale: 2,
                    duration: 1000,
                    easing: "easeInOutQuad"
                })
                anime({
                    targets: '#icash-2',
                    translateX: "-50%",
                    translateY: "-600%",
                    scale: 2,
                    duration: 1000,
                    easing: "easeInOutQuad"
                })
                anime({
                    targets: '#icash-3',
                    translateX: "-200%",
                    translateY: "-28%",
                    scale: 2,
                    opacity: 0,
                    duration: 1000,
                    easing: "easeInOutQuad"
                })
                anime({
                    targets: '#icash-device',
                    translateX: "-50%",
                    translateY: "-50%",
                    scale: 2,
                    duration: 1000,
                    easing: "easeInOutQuad"
                })
            }

            // if (size.width <= 1440 && size.width > 768) {
            //     anime({
            //         targets: '#icash-1',
            //         translateX: "-35%",
            //         translateY: "-3%",
            //         scale: .9,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#icash-2',
            //         translateX: "-35%",
            //         translateY: "-100%",
            //         scale: .9,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#icash-3',
            //         translateX: "-230%",
            //         translateY: "-30%",
            //         scale: .9,
            //         opacity: 1,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#icash-device',
            //         translateX: "-55%",
            //         translateY: "-30%",
            //         scale: .95,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            // } else if (size.width <= 768 && size.width > 480) {
            //     anime({
            //         targets: '#icash-1',
            //         translateX: "-45%",
            //         translateY: "-15%",
            //         scale: .8,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#icash-2',
            //         translateX: "-45%",
            //         translateY: "-70%",
            //         scale: .8,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#icash-3',
            //         translateX: "-200%",
            //         translateY: "-28%",
            //         scale: .8,
            //         opacity: 1,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#icash-device',
            //         translateX: "-55%",
            //         translateY: "-40%",
            //         scale: .8,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            // } else if (size.width <= 480) {
            //     anime({
            //         targets: '#icash-1',
            //         translateX: "-70%",
            //         translateY: "-75%",
            //         scale: .6,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#icash-2',
            //         translateX: "-70%",
            //         translateY: "-220%",
            //         scale: .6,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#icash-3',
            //         translateX: "-200%",
            //         translateY: "-28%",
            //         scale: .8,
            //         opacity: 0,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#icash-device',
            //         translateX: "-50%",
            //         translateY: "-75%",
            //         scale: .55,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            // } else {
            //     anime({
            //         targets: '#icash-1',
            //         translateX: "-35%",
            //         translateY: "-10%",
            //         scale: .95,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#icash-2',
            //         translateX: "-35%",
            //         translateY: "-135%",
            //         scale: .95,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#icash-3',
            //         translateX: "-260%",
            //         translateY: "-45%",
            //         scale: 1,
            //         opacity: 1,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#icash-device',
            //         translateX: "-60%",
            //         translateY: "-35%",
            //         scale: .95,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            // }
        } else {
            anime({
                targets: '#icash',
                opacity: 0,
                duration: 1000,
                easing: "easeInOutQuad"
            })

            anime({
                targets: '#icash-1',
                translateX: "-35%",
                translateY: "-3%",
                scale: 0,
                duration: 1000,
                easing: "easeInOutQuad"
            })
            anime({
                targets: '#icash-2',
                translateX: "-35%",
                translateY: "-125%",
                scale: 0,
                duration: 1000,
                easing: "easeInOutQuad"
            })
            anime({
                targets: '#icash-3',
                translateX: "-270%",
                translateY: "-40%",
                scale: 0,
                duration: 1000,
                easing: "easeInOutQuad"
            })
            anime({
                targets: '#icash-device',
                translateX: "-60%",
                translateY: "-30%",
                scale: 0,
                duration: 1000,
                easing: "easeInOutQuad"
            })
        }
    }, [entrance, size])

    return (
        <Box className="work-item" id="icash">
            <Center mt={size.width > 1440 ? 200 : 160}><Title weight={600} order={1} size={size.width > 480 ? 40 : 30} color="white">ICASH</Title></Center>
            <Center mb={20}><Title weight={400} order={size.width > 480 ? 2 : 3} color="white">Cash Management System</Title></Center>
            <Box id="icash-screens" w="100vw" h="100vh">
                <Box id="icash-device" p={15} pb={10}>
                    <img src="/img/icash-device.png" />
                </Box>
                <Box className="screen" id="icash-1" p={15} pb={10}>
                    <img src="/img/icash-1.png" />
                </Box>
                <Box className="screen" id="icash-2" p={15} pb={10}>
                    <img src="/img/icash-2.png" />
                </Box>
                <Box className="screen" id="icash-3" p={15} pb={10}>
                    <img src="/img/icash-3.png" />
                </Box>
            </Box>
        </Box>
    )
};