import { Box, Center, Grid, Group, Image, Text, Title } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import useScroll from "../../hooks/useScroll";
import './Team.scss';

export default function Team() {

    const scrollVal = useScroll();
    const [entrance, setEntrace] = useState(false);
    const team = useRef();

    useEffect(() => {
        var pos = team.current.getBoundingClientRect().top + scrollVal;
        setEntrace((pos - window.screen.height / 2) < scrollVal);
    }, [scrollVal])

    return (
        <Box ref={team} id="team" className="team" p={150}>
            <Grid sx={{ height: "fit-content" }} gutter="xl">
                <Grid.Col span={5}>
                    <Box id="content-image">
                        <Box className={entrance ? "entrance" : "exit"} id="top-left-border"></Box>
                        <Box className={entrance ? "entrance" : "exit"} id="bottom-right-border"></Box>
                        <Image id="main-image" className={entrance ? "entrance" : "exit"} styles={{
                            root: {
                                height: "100%"
                            },
                            image: {
                                borderRadius: 10
                            },
                            figure: {
                                height: "100%",
                            },
                            imageWrapper: {
                                height: "100%"
                            }
                        }} height="100%" fit="cover" src="/img/team.jpg" />
                        <Box id="content-label-container">
                            <Box className='content-label'>
                                <Box className={entrance ? "entrance" : "exit"} id="content-label-bg" />
                                <Text className={entrance ? "entrance" : "exit"} id="main-label" size={24} weight={600} align="center">Team has been together for <br/><span style={{ color: "#61c7d7" }}>over a decade</span><br/> Extensive experience in doing what we do</Text>
                            </Box>
                            <Box className={entrance ? "entrance" : "exit"} id="label-top-right-border"></Box>
                        </Box>
                    </Box>
                </Grid.Col>
                <Grid.Col span={7}>
                    <Box id="team-content" p={50} pl={70}>
                        <Group className={entrance ? "entrance" : "exit"}>
                            <Title id="pre-title" order={1} weight={600} color="#61c7d7" mr={20}>OUR AMAZING TEAM</Title>
                            <Box style={{ width: 120, height: 2, background: '#6accdb' }} />
                        </Group>
                        <Title id="title" className={entrance ? "entrance" : "exit"} mt={30} order={1} size={50} weight={600} mr={20}>We build business solutions for small to large enterprises</Title>
                        <Text className={"description " + (entrance ? "entrance" : "exit")} style={{textAlign:"justify"}} mt={30}>Clear Mind Algorithmics Inc. was established in 2013 as a software solutions company targeting to provide end-to-end business solutions for small to large enterprises. Our experienced and diverse team development gives us the skill set, abilities, resources and strategic capabilities to match the needs of our partners by building or customizing mobile and enterprise solutions on customer requirements. Clear Mind's solutions are reliable, highly scalable, and customizable, designed and coded by our world class mobile, web, and backend development team.</Text>
                        <Text className={"description " + (entrance ? "entrance" : "exit")} style={{textAlign:"justify"}} mt={20}>We, at Clear Mind, view our clients as long-term partners. We are committed to providing wholistic mobile, web, and enterprise solutions through collaborative evaluation of client business operations.</Text>
                    </Box>
                </Grid.Col>
            </Grid>
            <Text className={"description-mobile " + (entrance ? "entrance" : "exit")} style={{textAlign:"justify"}}  mt={50}>Clear Mind Algorithmics Inc. was established in 2013 as a software solutions company targeting to provide end-to-end business solutions for small to large enterprises. Our experienced and diverse team development gives us the skill set, abilities, resources and strategic capabilities to match the needs of our partners by building or customizing mobile and enterprise solutions on customer requirements. Clear Mind's solutions are reliable, highly scalable, and customizable, designed and coded by our world class mobile, web, and backend development team.</Text>
            <Text className={"description-mobile " + (entrance ? "entrance" : "exit")} style={{textAlign:"justify"}} mt={20}>We, at Clear Mind, view our clients as long-term partners. We are committed to providing wholistic mobile, web, and enterprise solutions through collaborative evaluation of client business operations.</Text>
        </Box>
    )
}