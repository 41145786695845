import { Box, Center, Title } from "@mantine/core"
import anime from "animejs"
import { useEffect } from "react"

export default function SFAA({ entrance, size }) {

    useEffect(() => {
        var heightRatio = size.height / 1602;
        var widthRatio = window.innerWidth / 2880;
        anime({
            targets: "#sfaa-screens",
            opacity: 1,
            scale: widthRatio,
            duration: 0,
        })
        if (entrance) {
            anime({
                targets: '#sfaa',
                opacity: 1,
                duration: 1000,
                easing: "easeInOutQuad"
            })

            if (size.width > 480) {

                var s1y = -50 * heightRatio;
                var s2y = -50 * heightRatio;
                var sdy = -40 * heightRatio;
                anime({
                    targets: '#sfaa-1',
                    translateX: "-30%",
                    translateY: `${s1y}%`,
                    scale: 1,
                    duration: 1000,
                    easing: "easeInOutQuad"
                })
                anime({
                    targets: '#sfaa-2',
                    translateX: "-230%",
                    translateY: `${s2y}%`,
                    scale: 1,
                    opacity: 1,
                    duration: 1000,
                    easing: "easeInOutQuad"
                })
                anime({
                    targets: '#sfaa-device',
                    translateX: "-55%",
                    translateY: `${sdy}%`,
                    scale: 1,
                    duration: 1000,
                    easing: "easeInOutQuad"
                })
            } else if (size.width <= 480 && size.width > 375) {

                anime({
                    targets: '#sfaa-1',
                    translateX: "-50%",
                    translateY: "-30%",
                    left: "50%",
                    scale: 2,
                    duration: 1000,
                    easing: "easeInOutQuad"
                })
                anime({
                    targets: '#sfaa-2',
                    translateX: "-140%",
                    translateY: "0%",
                    left: "50%",
                    scale: 2,
                    opacity: 0,
                    duration: 1000,
                    easing: "easeInOutQuad"
                })
                anime({
                    targets: '#sfaa-device',
                    translateX: "-50%",
                    translateY: "0%",
                    scale: 2,
                    duration: 1000,
                    easing: "easeInOutQuad"
                })
            }
            // if (size.width <= 1440 && size.width > 1024) {

            //     anime({
            //         targets: '#sfaa-1',
            //         translateX: "-35%",
            //         translateY: "-35%",
            //         left: "50%",
            //         scale: 1,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#sfaa-2',
            //         translateX: "-240%",
            //         translateY: "-35%",
            //         left: "50%",
            //         scale: 1,
            //         opacity: 1,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#sfaa-device',
            //         translateX: "-60%",
            //         translateY: "-30%",
            //         scale: .95,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            // } else if (size.width <= 1024 && size.width > 768) {

            //     anime({
            //         targets: '#sfaa-1',
            //         translateX: "-35%",
            //         translateY: "-30%",
            //         left: "50%",
            //         scale: 1,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#sfaa-2',
            //         translateX: "-240%",
            //         translateY: "-30%",
            //         left: "50%",
            //         opacity: 1,
            //         scale: 1,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#sfaa-device',
            //         translateX: "-60%",
            //         translateY: "-25%",
            //         scale: .95,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            // } else if (size.width <= 768 && size.width > 480) {

            //     anime({
            //         targets: '#sfaa-1',
            //         translateX: "-25%",
            //         translateY: "-50%",
            //         left: "50%",
            //         scale: 1,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#sfaa-2',
            //         translateX: "-210%",
            //         translateY: "-50%",
            //         left: "50%",
            //         scale: 1,
            //         opacity: 1,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#sfaa-device',
            //         translateX: "-60%",
            //         translateY: "-40%",
            //         scale: .9,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            // } else if (size.width <= 480 && size.width > 375) {

            //     anime({
            //         targets: '#sfaa-1',
            //         translateX: "-70%",
            //         translateY: "-35%",
            //         left: "50%",
            //         scale: .6,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#sfaa-2',
            //         translateX: "-140%",
            //         translateY: "-10%",
            //         left: "50%",
            //         scale: .6,
            //         opacity: 0,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#sfaa-device',
            //         translateX: "-72.5%",
            //         translateY: "-70%",
            //         scale: .55,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            // } else if (size.width <= 375) {

            //     anime({
            //         targets: '#sfaa-1',
            //         translateX: "-73%",
            //         translateY: "-20%",
            //         left: "50%",
            //         scale: .55,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#sfaa-2',
            //         translateX: "-140%",
            //         translateY: "0%",
            //         left: "50%",
            //         opacity: 0,
            //         scale: .55,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#sfaa-device',
            //         translateX: "-78%",
            //         translateY: "-65%",
            //         scale: .45,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            // } else {

            //     anime({
            //         targets: '#sfaa-1',
            //         translateX: "-40%",
            //         translateY: "-40%",
            //         left: "50%",
            //         scale: 1,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#sfaa-2',
            //         translateX: "-260%",
            //         translateY: "-40%",
            //         left: "50%",
            //         opacity: 1,
            //         scale: 1,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#sfaa-device',
            //         translateX: "-60%",
            //         translateY: "-35%",
            //         scale: .95,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            // }
        } else {
            anime({
                targets: '#sfaa',
                opacity: 0,
                duration: 1000,
                easing: "easeInOutQuad"
            })
            // anime({
            //     targets: '#sfaa-1',
            //     translateX: "-40%",
            //     translateY: "-30%",
            //     left: "70%",
            //     scale: 0,
            //     duration: 1000,
            //     easing: "easeInOutQuad"
            // })
            // anime({
            //     targets: '#sfaa-2',
            //     translateX: "-260%",
            //     translateY: "-30%",
            //     left: "30%",
            //     scale: 0,
            //     duration: 1000,
            //     easing: "easeInOutQuad"
            // })
            // anime({
            //     targets: '#sfaa-device',
            //     translateX: "-60%",
            //     translateY: "-20%",
            //     scale: 0,
            //     duration: 1000,
            //     easing: "easeInOutQuad"
            // })
        }
    }, [entrance, size])

    return (
        <Box className="work-item" id="sfaa">
            <Center px={10} mt={size.width > 1440 ? 200 : 160}><Title align="center" weight={600} order={1} size={size.width > 480 ? 40 : 30} color="white">SALES FORCE AUTOMATION APP</Title></Center>
            <Center px={10} mb={20}><Title px={10} align="center" weight={400} order={size.width > 480 ? 2 : 3} color="white">For Field Sales with Backend Web Application</Title></Center>
            <Box id="sfaa-screens" w="100vw" h="100vh">
                <Box id="sfaa-device" p={15} pb={10}>
                    <img src="/img/sfaa-device.png" />
                </Box>
                <Box className="screen" id="sfaa-1" p={15} pb={10}>
                    <img src="/img/sfaa-1.png" />
                </Box>
                <Box className="screen" id="sfaa-2" p={15} pb={10}>
                    <img src="/img/sfaa-2.png" />
                </Box>
            </Box>
        </Box>
    )
};