import { Box, Center, Title } from "@mantine/core"
import anime from "animejs"
import { useEffect } from "react"

export default function ECMD({ entrance, size }) {

    useEffect(() => {

        var heightRatio = size.height / 1602;
        var widthRatio = window.innerWidth / 2880;
        anime({
            targets: "#ecmd-screens",
            opacity: 1,
            scale: heightRatio,
            duration: 0,
        })
        if (entrance) {
            anime({
                targets: "#ecmd",
                opacity: 1,
                duration: 1000,
                easing: 'easeInOutQuad'
            })
            if (size.width > 480) {

                var e1y = 2.5 * heightRatio

                // if (size.height <= 1024) {

                //     anime({
                //         targets: "#ecmd-device",
                //         opacity: 1,
                //         scale: .8,
                //         translateX: "-60%",
                //         duration: 1000,
                //         easing: 'easeInOutQuad'
                //     })

                //     anime({
                //         targets: "#ecmd-1",
                //         opacity: 1,
                //         scale: .8,
                //         translateX: "0%",
                //         translateY: "-2.5%",
                //         duration: 1000,
                //         easing: 'easeInOutQuad'
                //     })
                //     anime({
                //         targets: "#ecmd-2",
                //         translateY: "8%",
                //         scale: .8,
                //         opacity: 1,
                //         translateX: "-150%",
                //         duration: 1000,
                //         easing: 'easeInOutQuad'
                //     })
                // } else {
                anime({
                    targets: "#ecmd-device",
                    opacity: 1,
                    scale: 1,
                    translateX: "-48.6%",
                    duration: 1000,
                    easing: 'easeInOutQuad'
                })

                anime({
                    targets: "#ecmd-1",
                    opacity: 1,
                    left: "50%",
                    bottom: "0%",
                    scale: 1,
                    translateX: "0%",
                    translateY: "-2.5%",
                    duration: 1000,
                    easing: 'easeInOutQuad'
                })
                anime({
                    targets: "#ecmd-2",
                    translateY: "-7%",
                    left: "50%",
                    bottom: "0%",
                    opacity: 1,
                    scale: 1,
                    translateX: "-150%",
                    duration: 1000,
                    easing: 'easeInOutQuad'
                })
            } else if (size.width <= 480) {
                anime({
                    targets: '#ecmd-1',
                    left: "50%",
                    translateY: "-35%",
                    translateX: "-56%",
                    bottom: "calc(0% + 40px)",
                    duration: 1000,
                    scale: .9,
                    easing: 'easeInOutQuad'
                })
                anime({
                    targets: '#ecmd-2',
                    left: "50%",
                    translateX: "-45%",
                    translateY: "-55%",
                    duration: 1000,
                    scale: .9,
                    opacity: 0,
                    easing: 'easeInOutQuad'
                })
                anime({
                    targets: '#ecmd-device',
                    translateX: "-50%",
                    // translateY: "-15%",
                    bottom: "-16px",
                    left: "calc(50% + 20px)",
                    duration: 1000,
                    scale: .95,
                    easing: 'easeInOutQuad'
                })
            }
            // if (size.width <= 1440 && size.width > 1024) {
            //     anime({
            //         targets: '#ecmd-1',
            //         left: "50%",
            //         translateX: "-25%",
            //         bottom: "calc(0% + 10px)",
            //         duration: 1000,
            //         scale: .65,
            //         easing: 'easeInOutQuad'
            //     })
            //     anime({
            //         targets: '#ecmd-2',
            //         left: "50%",
            //         translateX: "-80%",
            //         translateY: "-2%",
            //         duration: 1000,
            //         opacity: 1,
            //         scale: .65,
            //         easing: 'easeInOutQuad'
            //     })
            //     anime({
            //         targets: '#ecmd-device',
            //         translateX: "-50%",
            //         bottom: "-20px",
            //         left: "calc(50% + 20px)",
            //         duration: 1000,
            //         scale: .65,
            //         easing: 'easeInOutQuad'
            //     })
            // } else if (size.width <= 1024 && size.width > 768) {
            //     anime({
            //         targets: '#ecmd-1',
            //         left: "50%",
            //         translateX: "-25%",
            //         bottom: "calc(0% + 10px)",
            //         duration: 1000,
            //         scale: .6,
            //         easing: 'easeInOutQuad'
            //     })
            //     anime({
            //         targets: '#ecmd-2',
            //         left: "50%",
            //         translateX: "-80%",
            //         translateY: "-2%",
            //         duration: 1000,
            //         opacity: 1,
            //         scale: .6,
            //         easing: 'easeInOutQuad'
            //     })
            //     anime({
            //         targets: '#ecmd-device',
            //         translateX: "-50%",
            //         bottom: "-20px",
            //         left: "calc(50% + 20px)",
            //         duration: 1000,
            //         scale: .60,
            //         easing: 'easeInOutQuad'
            //     })
            // } else if (size.width <= 768 && size.width > 480) {
            //     anime({
            //         targets: '#ecmd-1',
            //         left: "50%",
            //         translateX: "0%",
            //         bottom: "calc(0% + 10px)",
            //         duration: 1000,
            //         scale: 1,
            //         easing: 'easeInOutQuad'
            //     })
            //     anime({
            //         targets: '#ecmd-2',
            //         left: "50%",
            //         translateX: "-130%",
            //         translateY: "-2%",
            //         opacity: 1,
            //         duration: 1000,
            //         scale: 1,
            //         easing: 'easeInOutQuad'
            //     })
            //     anime({
            //         targets: '#ecmd-device',
            //         translateX: "-50%",
            //         bottom: "-40px",
            //         left: "calc(50% + 20px)",
            //         duration: 1000,
            //         scale: 1,
            //         easing: 'easeInOutQuad'
            //     })
            // } else if (size.width <= 480) {
            //     anime({
            //         targets: '#ecmd-1',
            //         left: "50%",
            //         translateY: "-40%",
            //         translateX: "-56%",
            //         bottom: "calc(0% + 40px)",
            //         duration: 1000,
            //         scale: .9,
            //         easing: 'easeInOutQuad'
            //     })
            //     anime({
            //         targets: '#ecmd-2',
            //         left: "50%",
            //         translateX: "-45%",
            //         translateY: "-55%",
            //         duration: 1000,
            //         scale: .9,
            //         opacity: 0,
            //         easing: 'easeInOutQuad'
            //     })
            //     anime({
            //         targets: '#ecmd-device',
            //         translateX: "-50%",
            //         translateY: "-15%",
            //         bottom: "-100px",
            //         left: "calc(50% + 20px)",
            //         duration: 1000,
            //         scale: .95,
            //         easing: 'easeInOutQuad'
            //     })
            // } else {
            //     anime({
            //         targets: '#ecmd-1',
            //         left: "50%",
            //         bottom: "calc(0% + 20px)",
            //         duration: 1000,
            //         scale: .9,
            //         easing: 'easeInOutQuad'
            //     })
            //     anime({
            //         targets: '#ecmd-2',
            //         left: "50%",
            //         translateX: "-140%",
            //         duration: 1000,
            //         scale: .9,
            //         easing: 'easeInOutQuad'
            //     })
            //     anime({
            //         targets: '#ecmd-device',
            //         translateX: "-50%",
            //         bottom: "-20px",
            //         left: "calc(50% + 20px)",
            //         duration: 1000,
            //         scale: .9,
            //         easing: 'easeInOutQuad'
            //     })
            // }
        } else {
            anime({
                targets: "#ecmd",
                opacity: 0,
                duration: 1000,
                easing: 'easeInOutQuad'
            })
            anime({
                targets: '#ecmd-1',
                left: "80%",
                bottom: "calc(30% + 20px)",
                duration: 1000,
                scale: 0,
                easing: 'easeInOutQuad'
            })
            anime({
                targets: '#ecmd-2',
                left: "30%",
                translateX: "-140%",
                translateY: "-5%",
                duration: 1000,
                scale: 0,
                easing: 'easeInOutQuad'
            })
            anime({
                targets: '#ecmd-device',
                translateX: "-50%",
                // left: "calc(40% + 20px)",
                duration: 1000,
                scale: 0,
                easing: 'easeInOutQuad'
            })
        }
    }, [entrance, size])

    return (
        <Box className="work-item" id="ecmd">
            <Center mt={size.width > 1440 ? 180 : 160}><Title weight={600} order={1} size={size.width > 480 ? 40 : 30} color="white">ECMD</Title></Center>
            <Center mb={20}><Title px={10} align="center" weight={400} order={size.width > 480 ? 2 : 3} color="white">Healthcare App Our Partner App for teleconsultation</Title></Center>
            <Box id="ecmd-screens" w="100vw" h="100vh">
                <Box id="ecmd-device" p={15} pb={10}>
                    <img src="/img/ecmd-device.png" />
                </Box>
                <Box className="screen" id="ecmd-1" p={15} pb={10}>
                    <img src="/img/ecmd-1.png" />
                </Box>
                <Box className="screen" id="ecmd-2" p={15} pb={10}>
                    <img src="/img/ecmd-2.png" />
                </Box>
            </Box>
        </Box>
    )
};