import { useEffect, useState } from "react";

export default function useScroll(){
    const [scrollVal, setScrollVal] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScrollVal(window.scrollY)
        }, {passive: true});
    }, []);

    return scrollVal;
}