import { Box, Button, Group, Image, Stack, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import useScroll from "../../hooks/useScroll";
import BurgerIcon from "./BurgerIcon";
import CloseIcon from "./CloseIcon";
import './NavbarMobile.scss';

function NavbarItem({ label = '', active = false, pointer, onClick }) {

    const scrollVal = useScroll();

    const handleClick = () => {
        window.location.href = `#${pointer}`
        onClick();
    }

    return (
        <Button className="navbar-item" size="xl" styles={{
            root: {
                "&:not([data-disabled]):hover": {
                    background: 'transparent !important'
                },
                paddingLeft: 0,
                paddingRight: 0,
                borderRadius: 0,
            },
            label: {
                color: active ? "#6accdb" : 'black'
            }
        }} variant="subtle" uppercase color="white" onClick={handleClick}>
            <Text size={18} weight={500}>{label}</Text>
        </Button>
    )
}

export default function NavbarMobile() {
    const scrollVal = useScroll();
    const [visible, setVisible] = useState(false);
    const [sections, setSections] = useState({
        home: 0,
        services: null,
        team: null,
        work: null,
        contact: null
    });

    useEffect(() => {
        if (!sections.contact || !sections.home || !sections.services || !sections.team || !sections.work) {
            setSections({
                ...sections,
                services: (document.getElementById('services')?.getBoundingClientRect()?.top ?? 0) + scrollVal - 100,
                team: (document.getElementById('team')?.getBoundingClientRect()?.top ?? 0) + scrollVal - 100,
                work: (document.getElementById('work')?.getBoundingClientRect()?.top ?? 0) + scrollVal - 100,
                contact: (document.getElementById('contact')?.getBoundingClientRect()?.top ?? 0) + scrollVal - 100
            })
        }
    }, [sections.contact, sections.home, sections.services, sections.team, sections.work, scrollVal])

    const alpha = () => {
        return (scrollVal ?? 0) / window.screen.height;
    }

    const handleHide = () => {
        setVisible(false);
    }

    return (
        <>
            <Group position="apart" className='navbar-mobile' p={15} sx={{
                background: scrollVal >= sections.services ? 'white' : 'transparent',
                boxShadow: scrollVal >= sections.services ? '0px 0px 8px rgba(0,0,0,.16)' : 'none',
                transition: 0,
                width: "100%",
                position: 'fixed',
                zIndex: 99
            }}>
                <Image className="logo" width={60} src='/img/cmai-1.png' styles={{ root: { opacity: `${scrollVal >= sections.services ? 1 : 0} !important` } }} />
                <Button px={10} variant="subtle" onClick={() => setVisible(true)}>
                    <BurgerIcon color={scrollVal >= sections.services ? 'black' : "white"} />
                </Button>
            </Group>
            <Box p={20} className={`navbar-mobile-menu ${visible ? ' show' : ''}`}>
                <Group position="right">
                    <Button px={10} variant="subtle" onClick={handleHide}>
                        <CloseIcon />
                    </Button>
                </Group>
                <Stack>
                    <NavbarItem pointer='home' label="Home" active={scrollVal >= sections.home && scrollVal < sections.services} onClick={handleHide}/>
                    <NavbarItem pointer='services' label="Services" active={scrollVal >= sections.services && scrollVal < sections.team} onClick={handleHide}/>
                    <NavbarItem pointer='team' label="Team" active={scrollVal >= sections.team && scrollVal < sections.work} onClick={handleHide}/>
                    <NavbarItem pointer='work' label="Work" active={scrollVal >= sections.work && scrollVal < sections.contact} onClick={handleHide}/>
                    <NavbarItem pointer='contact' label="Contact" active={scrollVal >= sections.contact} onClick={handleHide}/>
                </Stack>
            </Box>
        </>
    );
}