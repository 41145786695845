import { Box, Center, Container, Group, Image, Text, Title } from "@mantine/core";
import ChevronDown from "./ChevronDown";
import Cog from "./Cog";
import './Home.scss';
//Pau
export default function Home(props) {
    return (
        <Box id="home" className="home">
            <Box className="cog-container">
                <Cog size={450} color="rgba(255,255,255,.03)" id="cog1" className="cog" />
                <Cog size={250} color="rgba(255,255,255,.03)" id="cog2" className="cog" />
                <Cog size={300} color="rgba(255,255,255,.03)" id="cog3" className="cog" />
            </Box>
            <Group className="content-container" position="center" spacing={100}>
                <Image src="img/cmai.png" fit="contain" id="home-logo" />
                <Box w="45%" className="title-container">
                    {/* <Text mb={20} size={30} style={{ color: "#6accdb" }} weight={500} className="pre-title">OUR SKILLED TEAM CREATE</Text>                     */}
                    <Title className="title" weight={600} size={50} color="white" style={{ lineHeight: 1.5 }}>Delivers highly functional end to end <span>mobile</span> and <span>web</span> based <span>applications</span> with robust backend systems</Title>
                </Box>
            </Group>
            <Box className="scroll-to-explore">
                <Center mb={10}>
                    <Text color="white">Scroll to explore</Text>
                </Center>
                <Center>
                    <ChevronDown color="white" id="chevron1" />
                </Center>
                <Center>
                    <ChevronDown color="white" id="chevron2" />
                </Center>
            </Box>
        </Box>
    )
}