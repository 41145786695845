import './App.css';
import { Box, Center, Image, Text } from '@mantine/core';
import Navbar from './sections/Navbar/Navbar';
import Home from './sections/Home/Home';
import Services from './sections/Services/Services';
import Banner from './sections/Banner/Banner';
import Team from './sections/Team/Team';
import Work from './sections/Work/Work';
import Contact from './sections/Contact/Contact';
import NavbarMobile from './sections/NavbarMobile/NavbarMobile';
import { useEffect, useState } from 'react';

function App() {

  const [dimensions, setDimensions] = useState({ height: window.innerHeight, width: window.innerWidth });

  useEffect(() => {
    window.addEventListener('resize', () => {
      setDimensions({ height: window.innerHeight, width: window.innerWidth });
    })
  }, [])

  return (
    <Box sx={{
      minHeight: '100vh',
      width: "100%"
    }}>
      <Image src="/img/high-five.jpg" height="100vh" id='bg-corp' />
      <Navbar />
      <NavbarMobile />
      <Home />
      <Banner />
      <Services />
      <Team />
      <Work />
      <Contact />
      <Box style={{ background: '#101619' }} p={20} px={150} id="copyright">
        <Text color="white" size="sm">© 2023 | Clear Mind Algorithmics Incorporated</Text>
      </Box>
    </Box>
  );
}

export default App;
