import { Box, Button, Grid, Group, Image, Textarea, TextInput, Title, Modal } from "@mantine/core";
import { useForm } from '@mantine/form';
import { useEffect, useRef, useState } from "react";
import useScroll from "../../hooks/useScroll";
import './Contact.scss';

export default function Contact() {
    const [open, setOpen] = useState(false);
    const [entrance, setEntrance] = useState(false);
    const scrollVal = useScroll();
    const contactus = useRef();
    const form = useForm({
        initialValues: {
          name: '',
          email: '',
          message:''
        },
        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        }
      });
    let dialogTimeoutId;
    
    useEffect(() => {
        var pos = contactus.current.getBoundingClientRect().top + scrollVal;
        if ((pos - window.screen.height / 2) < scrollVal) {
            setEntrance(true);
        } else {
            setEntrance(false)
        }
    }, [scrollVal])

    const showDialog = () => {
        setOpen(true);
        dialogTimeoutId = setTimeout(closeDialog, 1000); 
    };
    
    const closeDialog = () => {
        setOpen(false);
    };

    const sendMail = () => {        
        if(form.values.email.trim() !== '' && form.values.name.trim() !== '' && form.values.message.trim() !== ''){            
            const mailApi = 'https://cmaidev.ussc.com.ph/cmai-poc/cmai-website/mail.php';
            const formData = new FormData();
            formData.append("email",form.values.email);
            formData.append("name",form.values.name);
            formData.append("message",form.values.message);
            fetch(mailApi, {
                method: 'POST',
                body: formData            
            })
            .then((response) => response.json())
            .then(() => {      
                showDialog();          
                form.reset();                
            })
            .catch((err) => {
                console.log(err.message);
            });            
        }                  
    };

    return (
        <Box ref={contactus} id="contact" className="contact" p={150}>
            <Grid className="content-container">
                <Grid.Col span={6}>
                    <Box className={entrance ? "entrance" : "exit"} id="top-right-border"></Box>
                    <Box className={entrance ? "entrance" : "exit"} id="bottom-left-border"></Box>
                    <Box className={entrance ? "entrance" : "exit"} id="form-container"></Box>
                    <Box id="form-content">
                        <Title className={entrance ? "entrance" : "exit"} weight={400} order={2}>Contact us for more information on how we can help your business grow.</Title>
                        <TextInput className={entrance ? "entrance" : "exit"} mt="10%" label="Name" variant="unstyled" size="lg" style={{ borderBottom: "1px solid black" }} {...form.getInputProps('name')} />
                        <TextInput className={entrance ? "entrance" : "exit"} label="Email" variant="unstyled" size="lg" style={{ borderBottom: "1px solid black" }} {...form.getInputProps('email')}/>
                        <Textarea className={entrance ? "entrance" : "exit"} label="Message" maxRows={3} variant="unstyled" size="lg" style={{ borderBottom: "1px solid black" }} {...form.getInputProps('message')} />
                        <Group className={entrance ? "entrance" : "exit"} position="right">
                            <Button size="xl" w={200} radius="xl" styles={{
                                root: {
                                    backgroundColor: "#61c7d7",

                                    "&:hover": {
                                        backgroundColor: "#61c7d7 !important",
                                    }
                                }
                            }} onClick={sendMail}>Send</Button>
                        </Group>
                    </Box>                    
                </Grid.Col>
                <Grid.Col span={6}>
                    <Box className="contact-us-details" pl={100} pb="20%">
                        <Group className={entrance ? "entrance" : "exit"}>
                            <Title className="title" order={1} mr={20} color="#6accdb" weight={600}>CONTACT US</Title>
                            <Box style={{ width: 120, height: 2, background: '#6accdb' }} />
                        </Group>
                        <Title className={entrance ? "entrance" : "exit"} order={2} weight={500} mt="10%">Information</Title>
                        <Grid className={entrance ? "entrance" : "exit"} align="center">
                            <Grid.Col span={1}>
                                <Image height={80} width={80} fit="contain" src="/img/phone.png" />
                            </Grid.Col>
                            <Grid.Col span={9}>
                                <Title ml={50} order={3} weight={500}>+63 8 249-5374</Title>
                            </Grid.Col>
                        </Grid>
                        <Grid className={entrance ? "entrance" : "exit"} align="center">
                            <Grid.Col span={1}>
                                <Image height={80} width={80} fit="contain" src="/img/message.png" />
                            </Grid.Col>
                            <Grid.Col span={9}>
                                <Title ml={50} order={3} weight={500}>info@clearmindai.com</Title>
                            </Grid.Col>
                        </Grid>
                        <Grid className={entrance ? "entrance" : "exit"} align="center">
                            <Grid.Col span={1}>
                                <Image height={80} width={80} fit="contain" src="/img/location.png" />
                            </Grid.Col>
                            <Grid.Col span={9}>
                                <Title ml={50} order={3} weight={500}>5F RCPI Building, 711 EDSA Cor. New York St., Cubao, Quezon City, Philippines</Title>
                            </Grid.Col>
                        </Grid>
                    </Box>
                </Grid.Col>
            </Grid>           
            <Modal
                withCloseButton={false}    
                opened={open}     
                onClose={closeDialog}                         
                size="xs"                    
                styles={{
                    body: { backgroundColor: '#4BB543', color: 'white' }}}         
            >
                <div>Message Sent!</div>                
            </Modal>
        </Box>        
    )
}