import { Box, Center, Title } from "@mantine/core"
import anime from "animejs"
import { useEffect } from "react"

export default function UHRIS({ entrance, size }) {

    useEffect(() => {
        var heightRatio = size.height / 1602;
        var widthRatio = window.innerWidth / 2880;
        anime({
            targets: "#uhris-screens",
            opacity: 1,
            scale: widthRatio,
            duration: 0,
        })
        if (entrance) {
            anime({
                targets: '#uhris',
                opacity: 1,
                duration: 1000,
                easing: "easeInOutQuad"
            })
            if (size.width > 480) {

                var udy = -50 * heightRatio;
                var u1y = -55 * heightRatio;
                var u1x = -10 / heightRatio;
                var u2y = -65 * heightRatio;

                // if(size.height  )
                anime({
                    targets: '#uhris-device',
                    opacity: 1,
                    duration: 1000,
                    translateX: "-50%",
                    translateY: `${udy}%`,
                    scale: 1,
                    easing: "easeInOutQuad"
                })
                anime({
                    targets: '#uhris-1',
                    opacity: 1,
                    duration: 1000,
                    translateX: `${u1x}%`,
                    translateY: `${u1y}%`,
                    scale: 1,
                    easing: "easeInOutQuad"
                })
                anime({
                    targets: '#uhris-2',
                    opacity: 1,
                    duration: 1000,
                    translateX: "-160%",
                    translateY: `${u2y}%`,
                    scale: 1,
                    easing: "easeInOutQuad"
                })
            } else if (size.width <= 480) {
                anime({
                    targets: '#uhris-1',
                    translateX: '-50%',
                    translateY: '-150%',
                    scale: 2,
                    duration: 1000,
                    easing: "easeInOutQuad"
                })
                anime({
                    targets: '#uhris-2',
                    translateX: '-50%',
                    translateY: '55%',
                    scale: 2,
                    duration: 1000,
                    easing: "easeInOutQuad"
                })
                anime({
                    targets: '#uhris-device',
                    // translateX: '-50%',
                    translateY: '0%',
                    scale: 2,
                    duration: 1000,
                    easing: "easeInOutQuad"
                })
            }
            // if (size.width <= 768 && size.width > 480) {
            //     anime({
            //         targets: '#uhris-1',
            //         translateX: '-40%',
            //         translateY: '-30%',
            //         scale: .8,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#uhris-2',
            //         translateX: '-150%',
            //         translateY: '-30%',
            //         scale: .9,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#uhris-device',
            //         translateX: '-50%',
            //         translateY: '-50%',
            //         scale: .8,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            // } else if (size.width <= 480 && size.width > 375) {
            //     anime({
            //         targets: '#uhris-1',
            //         translateX: '-70%',
            //         translateY: '-65%',
            //         scale: .6,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#uhris-2',
            //         translateX: '-30%',
            //         translateY: '-5%',
            //         scale: .6,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#uhris-device',
            //         translateX: '-50%',
            //         translateY: '-60%',
            //         scale: .6,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            // } else if (size.width <= 375) {
            //     anime({
            //         targets: '#uhris-1',
            //         translateX: '-75%',
            //         translateY: '-60%',
            //         scale: .5,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#uhris-2',
            //         translateX: '-25%',
            //         translateY: '-10%',
            //         scale: .5,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#uhris-device',
            //         translateX: '-50%',
            //         translateY: '-70%',
            //         scale: .5,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            // } else {
            //     anime({
            //         targets: '#uhris-1',
            //         translateX: '-20%',
            //         translateY: '-25%',
            //         scale: 1,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#uhris-2',
            //         translateX: '-160%',
            //         translateY: '-25%',
            //         scale: 1,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            //     anime({
            //         targets: '#uhris-device',
            //         translateX: '-50%',
            //         translateY: '-30%',
            //         scale: 1,
            //         duration: 1000,
            //         easing: "easeInOutQuad"
            //     })
            // }
        } else {
            anime({
                targets: '#uhris',
                opacity: 0,
                duration: 1000,
                easing: "easeInOutQuad"
            })
            anime({
                targets: '#uhris-1',
                translateX: '-20%',
                translateY: '-20%',
                scale: 0,
                duration: 1000,
                easing: "easeInOutQuad"
            })
            anime({
                targets: '#uhris-2',
                translateX: '-160%',
                translateY: '-20%',
                scale: 0,
                duration: 1000,
                easing: "easeInOutQuad"
            })
            anime({
                targets: '#uhris-device',
                translateX: '-50%',
                translateY: '-26%',
                scale: 0,
                duration: 1000,
                easing: "easeInOutQuad"
            })
        }
    }, [entrance, size])

    return (
        <Box className="work-item" id="uhris">
            <Center mt={size.width > 1440 ? 200 : 160}><Title weight={600} order={1} size={size.width > 480 ? 40 : 30} color="white">UHRIS</Title></Center>
            <Center mb={20}><Title weight={400} order={size.width > 480 ? 2 : 3} color="white">An HR System Solution</Title></Center>
            <Box id="uhris-screens" w="100vw" h="100vh">
                <Box id="uhris-device" p={15} pb={10}>
                    <img src="/img/uhris-device.png" />
                </Box>
                <Box className="screen" id="uhris-1" p={15} pb={10}>
                    <img src="/img/uhris-1.png" />
                </Box>
                <Box className="screen" id="uhris-2" p={15} pb={10}>
                    <img src="/img/uhris-2.png" />
                </Box>
            </Box>
        </Box>
    )
};