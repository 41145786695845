import { Button, Group, Image, Text, Title } from "@mantine/core";
import React, { useEffect, useState } from "react";
import useScroll from "../../hooks/useScroll";
import './Navbar.scss';

function NavbarItem({ label = '', active = false, pointer, light = false }) {

    const scrollVal = useScroll();

    const colorVal = () => {
        return (((scrollVal ?? 0) / window.screen.height) < .3) ? 255 : 0
    }

    const handleClick = () => {
        window.location.href = `#${pointer}`
    }

    return (
        <Button className="navbar-item" mr={50} size="xl" styles={{
            root: {
                "&:not([data-disabled]):hover": {
                    background: 'transparent !important'
                },
                paddingLeft: 0,
                paddingRight: 0,
                borderRadius: 0,
                borderBottom: active && '2px solid #6accdb',
            },
            label: {
                color: active ? "#6accdb" : (light ? 'black' : 'white')
            }
        }} variant="subtle" uppercase color="white" onClick={handleClick}>
            <Text size={18} weight={500}>{label}</Text>
        </Button>
    )
}

export default function Navbar() {

    const scrollVal = useScroll();
    const [sections, setSections] = useState({
        home: 0,
        services: null,
        team: null,
        work: null,
        contact: null
    });

    useEffect(() => {
        if (!sections.contact || !sections.home || !sections.services || !sections.team || !sections.work) {
            setSections({
                ...sections,
                services: (document.getElementById('services')?.getBoundingClientRect()?.top ?? 0) + scrollVal - 100,
                team: (document.getElementById('team')?.getBoundingClientRect()?.top ?? 0) + scrollVal - 100,
                work: (document.getElementById('work')?.getBoundingClientRect()?.top ?? 0) + scrollVal - 100,
                contact: (document.getElementById('contact')?.getBoundingClientRect()?.top ?? 0) + scrollVal - 100
            })
        }
    }, [sections.contact, sections.home, sections.services, sections.team, sections.work, scrollVal])

    const alpha = () => {
        return (scrollVal ?? 0) / window.screen.height;
    }

    return (
        <Group position="center" className='navbar' py={10} sx={{
            background: scrollVal >= sections.services ? 'white' : 'transparent',
            boxShadow: scrollVal >= sections.services ? '0px 0px 8px rgba(0,0,0,.16)' : 'none',
            transition: 0,
            position: 'relative'
        }}>
            <Image className="logo" width={80} src='/img/cmai-1.png' styles={{ root: { opacity: `${scrollVal >= sections.services ? 1 : 0} !important` } }} />
            <NavbarItem pointer='home' label="Home" active={scrollVal >= sections.home && scrollVal < sections.services} light={scrollVal >= sections.services} />
            <NavbarItem pointer='services' label="Services" active={scrollVal >= sections.services && scrollVal < sections.team} light={scrollVal >= sections.services} />
            <NavbarItem pointer='team' label="Team" active={scrollVal >= sections.team && scrollVal < sections.work} light={scrollVal >= sections.services} />
            <NavbarItem pointer='work' label="Work" active={scrollVal >= sections.work && scrollVal <= sections.contact} light={scrollVal >= sections.services} />
            <NavbarItem pointer='contact' label="Contact" active={scrollVal >= sections.contact} light={scrollVal >= sections.services} />
        </Group>
    )
}