import { Box, Center, Text } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import useScroll from "../../hooks/useScroll";
import './Banner.scss';

export default function Banner() {

    const scrollVal = useScroll();
    const banner = useRef();
    const [entrance, setEntrace] = useState(false);

    useEffect(() => {
        var pos = banner.current.getBoundingClientRect().top + scrollVal;
        setEntrace((pos - window.screen.height/2) < scrollVal);
    }, [scrollVal])

    return (
        <Box ref={banner} id='banner'>
            <Center>
                <Text className={entrance ? 'banner-entrance' : 'banner-exit'} color="white" weight={500} w={1000} align="center" size={40}><i>"Our strength lies in our ability to understand our clients requirements and work collaboratively with them in creating the best solution."</i></Text>
            </Center>
        </Box>
    );
}