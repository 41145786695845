import { Carousel, Embla } from "@mantine/carousel";
import { Box, Button, Grid, Group, Image, Progress, Title } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import useScroll from "../../hooks/useScroll";
import ChevronLeft from "./ChevronLeft";
import ChevronRght from "./ChevronRight";
import ServiceCard from "./ServiceCard";
import './Services.scss';

export default function Services() {

    const scrollVal = useScroll();
    const [embla, setEmbla] = useState();
    const [progress, setProgress] = useState(0);
    const [entrance, setEntrace] = useState(false);
    const services = useRef();

    const handleNextSlide = () => {
        embla.scrollNext();
    }

    const handlePrevSlide = () => {
        embla.scrollPrev();
    }

    const handleSlideChange = (e) => {
        setProgress((e + 1) / 7);
    }

    useEffect(() => {
        var pos = services.current.getBoundingClientRect().top + scrollVal;
        setEntrace((pos - window.screen.height / 2) < scrollVal)
    }, [scrollVal])

    return (
        <Box ref={services} id="services" className="services" p={150} pr={0} pt={230}>
            <Grid gutter={100} className="main-content">
                <Grid.Col span={4}>
                    <Group className={entrance ? 'entrance-0' : 'exit-3'}>
                        <Title id="services-title" order={1} mr={20} color="#6accdb" weight={600}>SERVICES</Title>
                        <Box style={{ width: 120, height: 2, background: '#6accdb' }} />
                    </Group>                    
                    <Title id="content-text" className={entrance ? 'entrance-1' : 'exit-2'} mt={50} size={50} weight={600}>We have<br />ready-to-implement solutions, or we can build something for you from the ground up</Title>
                    <Group id="services-controls" mt={150} className={entrance ? 'entrance-2' : 'exit-1'}>
                        <Button disabled={progress === 1 / 7} mr={20} h={45} w={45} styles={{
                            root: {
                                padding: 0,
                                "&[data-disabled]": {
                                    backgroundColor: "#ace0ee"
                                },
                                "&:not([data-disabled])": {
                                    "&:hover": {
                                        background: '#6accdb'
                                    },
                                    background: "#6accdb"
                                }
                            }
                        }} radius="xl" onClick={handlePrevSlide}><ChevronLeft size={16} color="white" /></Button>
                        <Button disabled={progress === 1} h={45} w={45} styles={{
                            root: {
                                padding: 0,
                                "&[data-disabled]": {
                                    backgroundColor: "#ace0ee"
                                },
                                "&:not([data-disabled])": {
                                    "&:hover": {
                                        background: '#6accdb'
                                    },
                                    background: "#6accdb"
                                },
                            }
                        }} onClick={handleNextSlide} radius="xl"><ChevronRght size={16} color="white" /></Button>
                    </Group>
                    <Progress id="services-progress" className={entrance ? 'entrance-3' : 'exit-0'} mt={50} radius="xl" value={progress * 100} styles={{
                        root: {
                            height: 9,
                            background: "white",
                            border: "2px solid white",
                            boxShadow: "0 2px 2px rgba(0,0,0,.05)"
                        },
                        bar: {
                            background: '#6accdb',
                            transition: '.4s'
                        }
                    }} />
                </Grid.Col>
                <Grid.Col span={8}>
                    <Box sx={{ width: "115%", heigh: "100%" }}>
                        <Carousel
                            sx={{ left: entrance ? '0' : '70vw', transition: "1s" }}
                            height="100%"
                            slideSize="480"
                            withControls={false}
                            slideGap={30}
                            align="start"
                            getEmblaApi={setEmbla}
                            speed={80}
                            onSlideChange={handleSlideChange}
                        >
                            <Carousel.Slide style={{
                                cursor: progress === 1/7 ? "default" : "pointer"
                            }} onClick={() =>embla.scrollTo(0)} data-active={progress === 1 / 7}><ServiceCard index={1} title="Finance" iconSrc="/img/finance.png" cardImgSrc="/img/finance-img.avif" content={[
                                "Cash Management System",
                                "Bills Payment Enrollment System",
                                "Compliance System for AMLC Requirements",
                                "Customization of Investment Monitoring System, Enterprise & Mobile"
                            ]} /></Carousel.Slide>
                            <Carousel.Slide style={{
                                cursor: progress === 2/7 ? "default" : "pointer"
                            }} onClick={() =>embla.scrollTo(1)} data-active={progress === 2 / 7}><ServiceCard index={2} title="Retail" iconSrc="/img/retail.png" cardImgSrc="/img/retail-img.jpeg" content={[
                                "Tablet based Order & Inventory System",
                                "Mobile Mapping Application",
                                "Queue System",
                                "Branch Panic Button",
                                "Video Broadcast System"
                            ]} /></Carousel.Slide>
                            <Carousel.Slide style={{
                                cursor: progress === 3/7 ? "default" : "pointer"
                            }} onClick={() =>embla.scrollTo(2)} data-active={progress == 3 / 7}><ServiceCard index={3} title="Marketing" iconSrc="/img/marketing.png" cardImgSrc="/img/marketing-img.jpeg" content={[
                                "Market Research Application",
                                "Loyalty Program",
                                "Redemption Website"
                            ]} /></Carousel.Slide>
                            <Carousel.Slide style={{
                                cursor: progress == 4/7 ? "default" : "pointer"
                            }} onClick={() =>embla.scrollTo(3)} data-active={progress === 4 / 7}><ServiceCard index={4} title="Healthcare" iconSrc="/img/healthcare.png" cardImgSrc="/img/healthcare-img.jpeg" content={[
                                "Healthcare Mobile App",
                                "Hospital/Medical Center Scheduler",
                                "Doctors Clinic System"
                            ]} /></Carousel.Slide>
                            <Carousel.Slide style={{
                                cursor: progress === 5/7 ? "default" : "pointer"
                            }} onClick={() =>embla.scrollTo(4)} data-active={progress === 5 / 7}><ServiceCard index={5} title="Human Resource" iconSrc="/img/human-resource.png" cardImgSrc="/img/human-resource-img.jpeg" content={[
                                "Payroll System",
                                "Employee Evaluation System"
                            ]} /></Carousel.Slide>
                            <Carousel.Slide style={{
                                cursor: progress === 6/7 ? "default" : "pointer"
                            }} onClick={() =>embla.scrollTo(5)} data-active={progress === 6 / 7}><ServiceCard index={6} title="Sales" iconSrc="/img/sales.png" cardImgSrc="/img/sales-img.jpeg" content={[
                                "Sales Force Automation",
                            ]} /></Carousel.Slide>
                            <Carousel.Slide style={{
                                cursor: progress === 7/7 ? "default" : "pointer"
                            }} onClick={() =>embla.scrollTo(6)} data-active={progress === 7 / 7}><ServiceCard index={7} title="Warehousing" iconSrc="/img/warehousing.png" cardImgSrc="/img/warehousing-img.jpeg" content={[
                                "Warehouse System",
                                "Mobile Logistics Tracker"
                            ]} /></Carousel.Slide>
                        </Carousel>
                        <Group id="services-controls-mobile" mt={50} className={entrance ? 'entrance-2' : 'exit-1'}>
                            <Button disabled={!progress} mr={20} h={45} w={45} styles={{
                                root: {
                                    padding: 0,
                                    "&[data-disabled]": {
                                        backgroundColor: "#ace0ee"
                                    },
                                    "&:not([data-disabled])": {
                                        "&:hover": {
                                            background: '#6accdb'
                                        },
                                        background: "#6accdb"
                                    }
                                }
                            }} radius="xl" onClick={handlePrevSlide}><ChevronLeft size={16} color="white" /></Button>
                            <Button h={45} w={45} styles={{
                                root: {
                                    padding: 0,
                                    "&:not([data-disabled])": {
                                        "&:hover": {
                                            background: '#6accdb'
                                        },
                                        background: "#6accdb"
                                    },
                                }
                            }} onClick={handleNextSlide} radius="xl"><ChevronRght size={16} color="white" /></Button>
                        </Group>
                        <Progress id="services-progress-mobile" className={entrance ? 'entrance-3' : 'exit-0'} mt={50} radius="xl" value={progress * 100} styles={{
                            root: {
                                height: 9,
                                background: "white",
                                border: "2px solid white",
                                boxShadow: "0 2px 2px rgba(0,0,0,.05)"
                            },
                            bar: {
                                background: '#6accdb',
                                transition: '.4s'
                            }
                        }} />
                    </Box>
                </Grid.Col>
            </Grid>
        </Box>
    )
}