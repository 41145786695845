import { Box, Button, Group, Image, Title } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import useScroll from "../../hooks/useScroll";
import ECMD from "./ECMD";
import Pay1st from "./Pay1st";
import UHRIS from "./UHRIS";
import ICash from "./iCash";
import './Work.scss';
import SFAA from "./SFAA";

export default function Work() {    
    const [slideIndex, setSlideIndex] = useState(0);
    const [size, setSize] = useState({});

    const [values, setValues] = useState({
        projects: 24,
        clients: 9,
        devs: 31
    })
    const scrollVal = useScroll();
    const [entrance, setEntrance] = useState(false);
    const counter = useRef();
    const work = useRef();

    useEffect(() => {
        var workpos = work.current.getBoundingClientRect().top + scrollVal;
        if ((workpos - window.screen.height / 2) < scrollVal) {
            setEntrance(true);
        } else {
            setEntrance(false)
        }

    }, [scrollVal]);

    useEffect(() => {

        function handleResize() {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        window.addEventListener('resize', handleResize)
        handleResize();
        return () => window.removeEventListener('resize', handleResize);

    }, [])

    return (
        <Box ref={work} id="work" className="work" p={150} pt={150}>
            <Image styles={{
                image: {
                    height: "100%  !important"
                },
                root: {
                    height: "100%"
                },
                figure: {
                    height: "100%"
                },
                imageWrapper: {
                    height: "100%"
                },
            }} fit="cover" id="work-bg" src="/img/work.jpg" />
            <Pay1st entrance={entrance && slideIndex === 0} size={size} />
            <ECMD entrance={entrance && slideIndex === 1} size={size} />
            <UHRIS entrance={entrance && slideIndex === 2} size={size} />
            <ICash entrance={entrance && slideIndex === 3} size={size} />
            <SFAA entrance={entrance && slideIndex === 4} size={size} />
            <Title weight={600} color="#252737" id="our-work-title" order={2}>OUR WORK</Title>
            <Group spacing={0} id="slide-controls">
                <Button p={10} h={size.width > 768 ? 60 : 40} w={size.width > 768 ? 60 : 40} variant="subtle" onClick={() => {
                    if (slideIndex) {
                        setSlideIndex(slideIndex - 1);
                    } else {
                        setSlideIndex(4)
                    }
                }}>
                    <img height={size.width > 768 ? 40 : 20} src="/img/left-chevron.png" />
                </Button>
                <Box h={size.width > 768 ? 60 : 40} w={1} sx={{ background: "#ffffff80" }} />
                <Button p={10} h={size.width > 768 ? 60 : 40} w={size.width > 768 ? 60 : 40} variant="subtle" onClick={() => {
                    if (slideIndex < 4) {
                        setSlideIndex(slideIndex + 1);
                    } else {
                        setSlideIndex(0);
                    }
                }}>
                    <img height={size.width > 768 ? 40 : 20} src="/img/right-chevron.png" />
                </Button>
            </Group>
        </Box>
    )
}