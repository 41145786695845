import { Box, Center, Title } from "@mantine/core"
import anime from "animejs"
import { useEffect } from "react"

export default function Pay1st({ entrance, size }) {

    useEffect(() => {
        var heightRatio = size.height / 1602;
        var widthRatio = window.innerWidth / 2880;
        anime({
            targets: "#pay1st-screens",
            opacity: 1,
            scale: widthRatio,
            duration: 0,
        })
        if (entrance) {
            anime({
                targets: "#pay1st",
                opacity: 1,
                duration: 1000,
                easing: 'easeInOutQuad'
            })
            if (size.width > 480) {
                var pdy = -30 * heightRatio;
                var p1y = -65 * heightRatio;
                var p2y = 75 / heightRatio;
                var p2x = -30 / widthRatio;
                anime({
                    targets: "#pay1st-device",
                    translateX: "-55%",
                    translateY: `${pdy}%`,
                    duration: 1000,
                    easing: 'easeInOutQuad',
                    scale: 1,
                })
                anime({
                    targets: "#pay1st-1",
                    translateX: "-30%",
                    translateY: `${p1y}%`,
                    duration: 1000,
                    scale: 1,
                    easing: 'easeInOutQuad'
                })
                anime({
                    targets: "#pay1st-2",
                    translateX: `${p2x}%`,
                    translateY: `${p2y}%`,
                    duration: 1000,
                    scale: 1,
                    easing: 'easeInOutQuad'
                })

            } else if (size.width <= 480) {
                anime({
                    targets: '#pay1st-1',
                    // left: "50%",
                    translateX: "-50%",
                    translateY: "-90%",
                    scale: 2,
                    duration: 1000,
                    easing: 'easeInOutQuad'
                })
                anime({
                    targets: '#pay1st-2',
                    left: "50%",
                    translateX: "-50%",
                    translateY: "100%",
                    scale: 2,
                    duration: 1000,
                    easing: 'easeInOutQuad'
                })
                anime({
                    targets: '#pay1st-device',
                    left: "50%",
                    translateX: "-25%",
                    translateY: "0%",
                    scale: 2,
                    duration: 1000,
                    easing: 'easeInOutQuad'
                })
            } 
            // if (size.width <= 1024 && size.width > 768) {
            //     anime({
            //         targets: '#pay1st-1',
            //         left: "50%",
            //         translateX: "-20%",
            //         translateY: "-40%",
            //         // scale: 1.2,
            //         top: "50%",
            //         duration: 1000,
            //         easing: 'easeInOutQuad'
            //     })
            //     anime({
            //         targets: '#pay1st-2',
            //         left: "50%",
            //         translateX: "-120%",
            //         translateY: "140%",
            //         // scale: 1.2,
            //         duration: 1000,
            //         easing: 'easeInOutQuad'
            //     })
            //     anime({
            //         targets: '#pay1st-device',
            //         translateX: "-60%",
            //         translateY: "-20%",
            //         // scale: 1,
            //         duration: 1000,
            //         easing: 'easeInOutQuad'
            //     })
            // } else if (size.width <= 768 && size.width > 480) {
            //     anime({
            //         targets: '#pay1st-1',
            //         left: "50%",
            //         translateX: "-20%",
            //         translateY: "-50%",
            //         // scale: 1.3,
            //         top: "50%",
            //         duration: 1000,
            //         easing: 'easeInOutQuad'
            //     })
            //     anime({
            //         targets: '#pay1st-2',
            //         left: "50%",
            //         translateX: "-110%",
            //         translateY: "150%",
            //         // scale: 1.3,
            //         duration: 1000,
            //         easing: 'easeInOutQuad'
            //     })
            //     anime({
            //         targets: '#pay1st-device',
            //         translateX: "-60%",
            //         translateY: "-35%",
            //         // scale: .8,
            //         duration: 1000,
            //         easing: 'easeInOutQuad'
            //     })
            // } else if (size.width <= 480 && size.width > 375) {
            //     anime({
            //         targets: '#pay1st-1',
            //         left: "50%",
            //         translateX: "-50%",
            //         translateY: "-180%",
            //         // scale: 2,
            //         top: "50%",
            //         duration: 1000,
            //         easing: 'easeInOutQuad'
            //     })
            //     anime({
            //         targets: '#pay1st-2',
            //         left: "50%",
            //         translateX: "-50%",
            //         translateY: "20%",
            //         // scale: 2,
            //         duration: 1000,
            //         easing: 'easeInOutQuad'
            //     })
            //     anime({
            //         targets: '#pay1st-device',
            //         left: "50%",
            //         translateX: "-70%",
            //         translateY: "-70%",
            //         // scale: .6,
            //         duration: 1000,
            //         easing: 'easeInOutQuad'
            //     })
            // } else if (size.width <= 375) {
            //     anime({
            //         targets: '#pay1st-1',
            //         left: "50%",
            //         translateX: "-50%",
            //         translateY: "-180%",
            //         // scale: 2,
            //         top: "50%",
            //         duration: 1000,
            //         easing: 'easeInOutQuad'
            //     })
            //     anime({
            //         targets: '#pay1st-2',
            //         left: "50%",
            //         translateX: "-50%",
            //         translateY: "20%",
            //         // scale: 2,
            //         duration: 1000,
            //         easing: 'easeInOutQuad'
            //     })
            //     anime({
            //         targets: '#pay1st-device',
            //         left: "50%",
            //         translateX: "-75%",
            //         translateY: "-70%",
            //         // scale: .5,
            //         duration: 1000,
            //         easing: 'easeInOutQuad'
            //     })
            // } else {
            //     anime({
            //         targets: '#pay1st-1',
            //         left: "50%",
            //         translateX: "-30%",
            //         translateY: "-55%",
            //         // scale: 1,
            //         top: "50%",
            //         duration: 1000,
            //         easing: 'easeInOutQuad'
            //     })
            //     anime({
            //         targets: '#pay1st-2',
            //         left: "50%",
            //         translateX: "-100%",
            //         translateY: "90%",
            //         // scale: 1,
            //         duration: 1000,
            //         easing: 'easeInOutQuad'
            //     })
            //     anime({
            //         targets: '#pay1st-device',
            //         translateX: "-60%",
            //         translateY: "-27%",
            //         // scale: .95,
            //         duration: 1000,
            //         easing: 'easeInOutQuad'
            //     })
            // }
        } else {
            anime({
                targets: "#pay1st",
                opacity: 0,
                duration: 1000,
                easing: 'easeInOutQuad'
            })
            var p1y = -200 * heightRatio;
            var p2x = -150 * heightRatio;
            anime({
                targets: '#pay1st-1',
                translateX: "100%",
                translateY: `${p1y}%`,
                scale: 0,
                duration: 1000,
                easing: 'easeInOutQuad'
            })
            anime({
                targets: '#pay1st-2',
                left: "30%",
                translateX: `${p2x}%`,
                // translateY: "100%",
                scale: 0,
                duration: 1000,
                easing: 'easeInOutQuad'
            })
            anime({
                targets: '#pay1st-device',
                scale: 0,
                duration: 1000,
                easing: 'easeInOutQuad'
            })
        }
    }, [entrance, size])

    return (
        <Box className="work-item" id="pay1st">
            <Center mt={size.width > 1440 ? 200 : 160}><Title weight={600} order={1} size={size.width > 480 ? 40 : 30} color="white">PAY1ST</Title></Center>
            <Center mb={20}><Title weight={400} order={size.width > 480 ? 2 : 3} color="white">A Payments Platform</Title></Center>
            <Box id="pay1st-screens" w="100vw" h="100vh">
                <Box id="pay1st-device" p={15} pb={10}>
                    <img src="/img/pay1st-device.png" />
                </Box>
                <Box className="screen" id="pay1st-1" p={size.width > 480 ? 15 : 10} pb={size.width > 480 ? 10 : 5}>
                    <img src="/img/pay1st-1.png" />
                </Box>
                <Box className="screen" id="pay1st-2" p={size.width > 480 ? 15 : 10} pb={size.width > 480 ? 10 : 5}>
                    <img src="/img/pay1st-2.png" />
                </Box>
            </Box>
        </Box>
    )
};